.dashboard {
  display: flex;
  .dashboard-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .expanded {
    width: 339px;
    transition: 0.2s ease;
  }
  .default {
    width: 0px;
    transition: 0.2s ease;
  }
  .sidebar-wrapper {
    position: relative;
    .expand-icon {
      display: none;
      position: absolute;
      right: -10px;
      cursor: pointer;
      top: 28px;
      z-index: 999;
      height: 30px;
      width: 30px;
      // display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: var(--color-gray-400);
    }
    .move-icon {
      right: -20px;
    }
  }
  .dashboard-sidebar {
    max-height: calc(100vh - 51px);
    overflow-y: auto;
    height: 100%;
    border-right: 1px solid var(--color-gray-300);
  }
  .dashboard-container {
    width: 100%;
    height: calc(100vh - 51px);
    padding: 3.2rem 7rem 0 6.1rem;
    position: relative;
    overflow-y: auto;
    .outlet-container {
      .modal-container.version-updates-modal {
        .modal {
          overflow: scroll;
          display: flex;
          flex-direction: column;
          gap: 28px;
          padding: 32px;
          width: 50%;
          min-width: 400px;
          max-width: 50%;
          height: 85%;
          max-height: 90%;
          background: var(--color-gray-300);
          border: 1px solid var(--color-gray-400);

          &::-webkit-scrollbar-corner {
            background: var(--color-gray-300);
          }

          .version-title {
            font-weight: 700;
            font-size: 2.5rem;
            letter-spacing: 4px;
          }

          .version-improvements {
            .changes-headers {
              letter-spacing: 3px;
            }
            .version-improvements-list {
              padding-left: 40px;

              li {
                font-size: 1.2rem;
                padding-bottom: 12px;
              }
              ul {
                padding-top: 12px;
                padding-left: 40px;

                li {
                  font-size: 1.2rem;
                  font-weight: 100;
                }
              }
            }
          }

          .btn-group {
            margin: 0;
            .button {
              align-self: flex-end;
            }
          }
        }
      }
    }
  }

  footer {
    max-width: calc(1600px - 67px);
    position: fixed;
    bottom: 0;
    height: 51px;
    width: 100%;
    color: var(--color-gray-400);
    .footer {
      border-top: 1px solid var(--color-gray-100);
      padding: 15px 0 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 62px;
      }

      .footer-left-text {
        gap: 8px;
        align-items: center;
        .version-number {
          gap: 8px;
          color: var(--color-white);
          transition: all 0.3s linear;
          border: 2px solid var(--color-persianblue);
          background: var(--color-persianblue);
          padding: 2px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 5px;

          cursor: pointer;
          &:hover {
            background: var(--color-persianblue-dark);
            border: 2px solid var(--color-persianblue-dark);
            color: var(--color-gray-100) !important;
            transition: all 0.3s linear;
          }

          svg {
            margin-top: 1px;
            // color: var(--color-persianblue);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .dashboard {
    .dashboard-container {
      padding: 3.2rem 3rem 0 2rem;
      overflow-y: auto;
    }
  }
}
