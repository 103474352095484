.add-staff {
  .add-staff-container {
    select {
      background: transparent;
      border: none;
      border-bottom: 1px solid var(--color-gray-400);
      color: var(--color-white);

      option {
        background: var(--color-gray-200);
      }
      &:focus {
        outline: none;
      }
    }
    .first-card {
      display: flex;
      gap: 18px;
      height: 100%;
      width: 100%;
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;
      transition: all 0.2s linear;
      &:hover {
        background-color: var(--color-gray-300);
        transition: all 0.2s linear;
      }
      .title-bar {
        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        flex-direction: row;
        padding-bottom: 0;
      }
    }

    .details-container {
      display: flex;
      flex-direction: row;
      gap: 0px 20px;

      &.staff-access {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content;
        // grid-template-areas:
        //   "user role memberID"
        //   "join-date brand home";

        // .user {
        //   grid-area: user;
        // }
        // .role {
        //   grid-area: role;
        // }
        // .member-ID {
        //   grid-area: memberID;
        // }
        // .joining-date {
        //   grid-area: join-date;
        // }
        .brand {
          // grid-area: brand;
          h3 {
            margin-bottom: 3px;
          }
          select {
            width: 100%;
            padding-bottom: 1.5px;
            border: none;
            border-bottom: 1px solid var(--color-gray-100);
            outline: none;
            color: var(--color-white);
          }
        }
        .home {
          // grid-area: home;
          h3 {
            margin-bottom: 5px;
          }
          input {
            color: var(--color-white);
            background: none;
            border: none;
            outline: none;
            padding-bottom: 2px;
            border-bottom: 1px solid var(--color-gray-100);
          }
        }
        .detail {
          div {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              width: 37%;
            }
            &:nth-child(3) {
              width: 25%;
            }
          }
        }
        @media screen and (max-width: 970px) {
          grid-template-columns: repeat(2, 45%);
        }
        @media screen and (max-width: 450px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &.staff-gen-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: min-content;
        gap: 28px 18px;
        @media screen and (max-width: 970px) {
          grid-template-columns: repeat(2, 45%);
        }
        @media screen and (max-width: 450px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &.address-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: row;
        grid-template-areas:
          "address address address address"
          "suburb state country post";

        .address {
          grid-area: address;
        }

        .suburb {
          grid-area: suburb;
        }

        .state {
          grid-area: state;
        }

        .country {
          grid-area: country;
        }

        .post {
          grid-area: post;
        }
      }

      &.contact-details {
        display: grid;
        gap: 18px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: min-content;
        grid-template-areas:
          "mobile mobile mobile . . ."
          "email email email sec-email sec-email sec-email"
          "address address address suburb suburb suburb"
          "state state post post country country";

        .mobile {
          grid-area: mobile;
        }
        .email {
          grid-area: email;
        }
        .sec-email {
          grid-area: sec-email;
        }
        .address {
          grid-area: address;
        }
        .suburb {
          grid-area: suburb;
        }
        .state {
          grid-area: state;
        }
        .post {
          grid-area: post;
        }
        .country {
          grid-area: country;
        }
        .detail {
        }
        @media screen and (max-width: 450px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-areas: unset;

          .mobile,
          .email,
          .sec-email,
          .address,
          .suburb,
          .state,
          .post,
          .country,
          .detail {
            grid-area: unset;
          }
        }
      }

      .detail {
        display: flex;
        flex: 1;
        flex-direction: column;
        // border-bottom: 1px solid var( --color-gray-400);

        .international-phone-input-container,
        .form-group,
        .select-element {
          flex: 1;
          input {
            width: 100%;
          }
        }

        .international-phone-input-container {
          p {
            padding: 0;
          }
        }

        &.row {
          flex-direction: row;
          gap: 20px;
          div {
            display: flex;
            flex-direction: column;
            label {
              margin-bottom: 5px;
            }
            p {
              padding-bottom: 3px;
              border-bottom: 1px solid var(--color-gray-100);
            }
          }
        }

        h3 {
          color: var(--color-gray-400);
          padding-bottom: 5px;
        }

        p {
          text-transform: capitalize;
          padding-bottom: 5px;
        }
      }
    }

    form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}
.add-attendee-row {
  margin-top: 25px;
}

.profile-img-container {
  position: relative;
  width: 213px;
  height: 216px;
}

/* Make the image responsive */
.profile-img-container img {
  width: 213px;
  height: 216px;
  border-radius: 20px;
  background: var(--color-gray-300);
  object-fit: cover;
  &.dim {
    opacity: 0.8;
  }
}

/* Style the button and place it in the middle of the container/image */
.profile-img-container .btn {
  position: absolute;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #3f3f3f;
  color: white;
  font-size: 12px;
  padding: 9px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.container .btn:hover {
  background-color: var(--color-gray-300);
  transition: all 0.2s linear;
}
.personal-information-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.staff-access-card,
.contact-information-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-100);
  width: 100%;

  border-radius: 5px;
}
.staff-img-input {
  display: none;
}
