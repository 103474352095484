.profile-page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .personal-info-card,
  .contact-info-card,
  .employment-card {
    display: flex;
    gap: 0px 18px;
    border: 1px solid var(--color-gray-400);
    border-radius: 5px;
    padding: 18px;

    .form-group1 {
      width: 100%;
    }

    .title-bar {
      width: 100%;
      padding-bottom: 0px;
    }

    .info-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      .staff-info {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr 1fr;
        gap: 26px 18px;
        grid-auto-flow: row;
        grid-template-areas:
          "First Middle Last"
          "DOB gender .";

        .Title {
          grid-area: Title;
        }

        .First {
          grid-area: First;
        }

        .Middle {
          grid-area: Middle;
        }

        .Last {
          grid-area: Last;
        }

        .DOB {
          grid-area: DOB;
        }

        .gender {
          grid-area: gender;
        }

        @media screen and (max-width: 900px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            "First Middle "
            "Last DOB"
            " gender .";
        }
        @media screen and (max-width: 420px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-areas:
            "First "
            "Middle "
            "Last "
            "DOB"
            " gender ";
        }
      }
    }
    .img-container {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 214px;
      min-height: 216px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        min-height: 216px;
        object-fit: cover;
        border-radius: 5px;
        outline: none;
        border: none;
        background: var(--color-gray-300);
      }
      .btn {
        position: absolute;
        width: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-color: #3f3f3f;
        color: white;
        font-size: 12px;
        padding: 9px 12px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        white-space: nowrap;
        transition: all 0.2s linear;

        &:hover {
          background-color: var(--color-gray-300);
          transition: all 0.2s linear;
        }
      }
    }
  }

  .contact-info-card {
    flex-direction: column;
    .details-section {
      display: grid;
      grid-auto-rows: min-content;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 18px 18px;
      grid-auto-flow: row;
      grid-template-areas:
        "mobile mobile mobile . . ."
        "prim-email prim-email prim-email sec-email sec-email sec-email"
        "address address address suburb suburb suburb"
        "state state post post country country";

      .mobile {
        grid-area: mobile;
      }

      .prim-email {
        grid-area: prim-email;
      }

      .sec-email {
        grid-area: sec-email;
      }

      .address {
        grid-area: address;
      }

      .suburb {
        grid-area: suburb;
      }

      .state {
        grid-area: state;
      }

      .post {
        grid-area: post;
      }

      .country {
        grid-area: country;
      }

      @media screen and (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: unset;

        .mobile,
        .prim-email,
        .sec-email,
        .address,
        .suburb,
        .state,
        .post,
        .country,
        .detail {
          grid-area: unset;
        }
      }
    }
  }

  .employment-card {
    flex-direction: column;
    margin-bottom: 20px;
    .employment-info {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 18px;
      grid-auto-flow: row;
      grid-auto-rows: min-content;

      @media screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    // .join-date {
    //   grid-area: join-date;
    // }

    .join-date,
    .brand-id,
    .home-club {
      input,
      select {
        // font-size: 10px;
      }
    }

    .brand-id {
      // grid-area: brand-id;
      display: flex;
      flex-direction: column;

      input {
        padding-bottom: 2px;
      }
      h5 {
        margin-bottom: 5px;
      }
      select {
        padding-left: 0;
      }
    }

    .home-club {
      // grid-area: home-club;

      .location-heading {
        margin-bottom: 5px;
      }

      input,
      p {
        //this is for styling during 'editing'
        color: var(--color-white);
        background: none;
        border: none;
        outline: none;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--color-gray-100);
      }
    }

    .type {
      // grid-area: type;
    }

    .alias {
      // grid-area: alias;
    }

    .role {
      // grid-area: role;
    }
  }
}
