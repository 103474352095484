.billing-info-container {
  .primarylabel {
    position: absolute;
    left: 0;
    top: 0;
  }

  .tabs {
    .tabs-list {
      > div {
        position: relative;
      }
    }

    .loading {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  .member-payment-title {
    display: flex;
    justify-content: space-between;

    h3 {
      padding-bottom: 20px;
    }

    .action-container {
      display: flex;
      gap: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .member-payment-display {
    .first-row {
      display: flex;
      width: 100%;
      gap: 24px;
      justify-content: space-between;

      h3 {
        padding-bottom: 5px;
        color: var(--color-gray-400);
        text-transform: capitalize;
      }

      p {
        color: var(--color-white);
        border-bottom: 1px solid var(--color-gray-400);
        padding-bottom: 3px;
      }

      div,
      span {
        flex: 1;
      }

      &.debit {
        padding-bottom: 0;
        gap: 1.2rem;
        flex-wrap: nowrap;

        .pay-type,
        .names,
        .number {
          width: 100%;
        }

        .pay-type {
          gap: 13px;
        }
      }

      &.credit {
        gap: 1.2rem;
        flex-wrap: nowrap;
        .pay-type,
        .names,
        .number {
          width: 100%;

          .bank-row {
            max-width: 48px;

            .bank-img {
              width: 48px;
              height: 33px;
              object-fit: cover;
            }
          }

          .content {
            height: 18px;
          }
        }

        .cvv {
          width: 100%;
          max-width: 60px;

          .content {
            height: 18px;
          }
        }

        .expiry {
          width: 100%;
          // max-width: 85px;

          h3 {
            white-space: nowrap;
          }

          .content {
            height: 18px;
          }
        }
      }
    }
  }
}

.payments-history.table {
  td,
  th {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: 12%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
    }

    &:nth-child(8) {
      width: 16%;
      text-align: left;
    }

    &:nth-child(6) {
      text-align: center;
      width: 10%;

      .flex-row {
        justify-content: flex-end;
        gap: 12px;
      }
    }

    &:nth-child(5) {
      text-align: left;
      width: 0%;
      padding-left: 40px;
    }
  }
  td {
    &:nth-child(6) {
      text-align: right;
    }
  }
}

@media screen and (max-width: 1290px) {
  .member-payment-display {
    h3 {
      height: 36px;
    }
  }
}
