.upgrade-downgrade-main-container {
  .location-UD-content {
    .disabled-text {
      color: gray;
    }
    // .pagination {
    //   margin-top: -50px;
    // }
    .table-btn-container {
      margin-top: 10px;
    }
    .first-row-settings {
      width: 100%;
      gap: 18px;

      .option-container {
        flex: 1;

        &.location-details {
          label {
            margin-bottom: 7px;
          }
          .select-element-value {
            background-color: transparent;
            min-width: 200px;
            border: 0;
            outline: 0;
            border-bottom: 1px solid var(--color-gray-100);
            color: var(--color-white);
            font-family: "gotham";
            transition: all 0.2s linear;
            option {
              background-color: var(--color-gray-200);
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  .payment-UG-content {
    .info-card {
      .option-container {
        gap: 18px;
        width: 100%;

        .flex-col {
          h5 {
            margin-bottom: 5px;
          }
          p {
            color: var(--color-white);
          }
        }
        .flex-row {
          justify-content: space-between;
          padding-right: 100px;
          gap: 18px;
        }
      }

      &.sec-container {
        h3,
        span {
          color: var(--color-white);
        }
        span {
          padding-left: 10px;
        }
        .checked-options-container {
          gap: 18px;
        }
      }
    }
  }
  .conditions-UG-container {
    h3 {
      color: var(--color-white);
    }
  }
  .confirmation-UG-container {
    h3 {
      color: var(--color-white);
    }
    .summary-content {
      width: 100%;
      gap: 18px;

      .flex-row {
        width: 100%;
        gap: 18px;
        &.first {
          width: calc(50% - 10px);
        }
        .flex-col {
          flex: 1;
          h5 {
            margin-bottom: 5px;
          }
          p {
            color: var(--color-white);
            padding-bottom: 2px;
            border-bottom: 1px solid var(--color-gray-100);
          }
        }
      }
      .pro-rata {
        p {
          color: var(--color-white);
        }
        span {
          padding-left: 2px;
          color: var(--color-persianblue);
          &:last-child {
            color: var(--color-white);
          }
        }
      }
    }
  }
}
input[type="radio"] {
  accent-color: #2757ff;
}
