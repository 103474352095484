.member {
  .member-container {
    position: relative;

    .member-info {
      .row2 {
        display: flex;
        justify-content: flex-end;
      }
    }
    .member-filter {
      position: absolute;
      top: 32px;
      left: -20px;
      z-index: 1000;
    }
    // .inline {
    //   display: flex;
    //   gap: 10px;
    //   position: absolute;
    //   right: 20px;
    //   z-index: 1;
    // }
    .table {
      margin-top: 32px;
      table {
        th,
        td {
          &:nth-child(1) {
            text-align: center;
          }
        }
        th {
          &:nth-child(4) {
            position: relative;
          }
        }
      }
      .row-action-container {
        display: flex;
        gap: 10px;
      }
      .beat-or-send,
      .fitness-passport,
      .view-details {
        width: 25px;
        height: 20px;
      }
      .email-btn-container {
        display: flex;
        gap: 10px;
      }
      .icon {
        display: flex;
        gap: 8px;
        align-items: center;
        position: relative;
      }
      .sortpopupicon {
        left: -20px;
        position: absolute;
        svg {
          color: var(--color-grey);
        }
      }

      td {
        // Name column
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 30%;
        }
      }
    }

    .brandwise-table {
      .table {
        table {
          td,
          th {
            &:nth-child(1) {
              width: 8%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 30%;
              div {
                justify-content: left;
              }
            }
            &:nth-child(5) {
              width: 10%;
            }
            &:nth-child(6) {
              width: 10%;
            }
          }
        }
      }
    }
  }
}
.active-contract-number {
  background-color: var(--color-green);
  width: 70px;
  color: black;
  text-align: center;
  border-radius: 12px;
  padding: 5px;
}
