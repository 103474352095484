/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray-2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-900);
}

.sidebar {
  width: 100%;
  height: 100%;
  max-width: 339px;
  // border-right: 1px solid var(--color-gray-300);
  // overflow-y: scroll;
  .no-show {
    display: none;
  }

  .sidebar-container {
    height: 100%;
    .active {
      background: red;
    }
    .header {
      display: flex;
      flex-direction: column;
      z-index: 100;
      gap: 14px;
      padding: 3.2rem 3.2rem 2.3rem 1.4rem;
      position: sticky;
      top: 0px;
      background: var(--color-black);
      // border-bottom: 0.5px solid var(--color-gray-400);
      z-index: 100;
      .brand-selector {
        display: flex;
        justify-content: space-between;
        gap: 4px;

        .logo {
          display: flex;
          justify-content: flex-start;
          max-width: 293px;
          height: 38px;
          color: white;
          margin-left: 0px;
          margin-bottom: 4px;

          .select-brand {
            position: absolute;
            background: white;
          }

          img {
            align-self: center;
            max-width: 260px;
          }
        }
        .arrow {
          align-self: center;
          margin-left: -30px;
        }
      }
      .brand-list-dropdown {
        position: relative;
        max-width: 260px;
        ul {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: -16px;
          background: var(--color-gray-300);
          border-radius: 5px;
          width: 100%;
          max-width: 260px;
          text-align: center;
          max-height: 340px;
          overflow: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          li {
            display: flex;
            justify-content: center;
            flex: 1;
            padding: 18px;
            border: 0px;
            transition: all 0.2s linear;

            // min-height: 74px;
            // max-height: 74px;

            &:hover {
              background: var(--color-gray-200);
              transition: all 0.2s linear;
            }

            &:empty {
              display: none;
            }
          }
        }
      }

      .staff-heading {
        margin: 2.8rem 0 0.3rem 1rem;
        color: var(--color-gray-100);
        font-size: 1.4rem;
      }
      .staff-value {
        margin-left: 1rem;
        font-size: 1.4rem;
      }
      .heading-and-icon {
        display: flex;
        gap: 10px;
        svg {
          align-self: flex-end;
          height: 15px;
          width: 15px;
          margin-bottom: 4px;
        }
      }
      .location-heading {
        color: var(--color-gray-100);
        margin: 0.8rem 0 0.4rem 0.5rem;
      }
      .location-value {
        color: var(--color-persianblue);
        text-transform: uppercase;
        font-size: 1.8rem;

        &.loc-select {
          display: flex;
          padding-right: 20px;
          margin-left: 5px;

          .select-element {
            margin-top: 0;
            align-self: center;
            background: transparent;
            outline: none;
            border: none;
            color: var(--color-persianblue-dark);
            text-transform: uppercase;
            width: 100%;

            &::-webkit-calendar-picker-indicator {
              color: var(--color-gray-400);
              margin-top: -3px;
            }

            &::placeholder {
              color: var(--color-persianblue);
              font-size: 14px;
            }
          }
        }
      }
    }
    .sidebar-list {
      height: 100%;
      letter-spacing: 2px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;

      .sidebar-main-items {
        flex: 1; //get sidebar to go full height
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100vh - 220px);

        .sidebar-item {
          text-transform: uppercase;
          cursor: pointer;
        }

        .sidebar-subitem {
          border: none;
          // padding-top: 9px !important;
          // padding-bottom: 4px ;
          text-transform: capitalize;
          padding: 14px 10px 14px 48px;
          background: var(--color-black);
          transition: all 0.2s linear;

          &:hover {
            background: var(--gray-5);
            transition: all 0.2s linear;
          }
        }
        // &:last-child {
        //   padding-bottom: 2px;
        // }

        // &.has-padding {
        //   padding-bottom: 8px;
        // }
      }

      .has-subitems {
        display: flex;
        span {
          margin-left: auto;
        }
      }

      ul {
        list-style: none;
        .dropdown-link {
          display: flex;
          position: relative;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid var(--color-gray-300);
          // border-bottom: 0.5px solid var(--color-gray-300);
          cursor: pointer;
          &:hover {
            background: var(--gray-5);
          }
          li {
            padding: 1.8rem 2.4rem 1.8rem 2.4rem;
            border: none;
            // &:hover {
            //   background: rgba(255,255,255, 0.05);
            // }
            &.dashboard {
              border-top: none;
            }
          }
          span {
            position: absolute;
            right: 0;
            margin-right: 2.4rem;
            margin-top: 5px;

            svg {
              width: 14px;
              height: 17px;
              transform: rotate(0deg);
              transition: transform 0.3s;
            }
          }

          &.open {
            border-bottom: 1px solid var(--color-gray-300);
            svg {
              transform: rotate(180deg);
            }
          }
        }

        li {
          padding: 1.8rem 2.4rem 1.8rem 2.4rem;
          border-top: 1px solid var(--color-gray-300);
          // &:hover {
          //   background: rgba(255,255,255, 0.05);
          // }
          &.dashboard {
            border-top: none;
          }
        }

        a li {
          &:hover {
            background: var(--gray-5);
          }
        }

        .sidebar-blank {
          padding: 2.4rem;
          &:hover {
            background: none;
          }
        }
      }
      .bottom {
        li {
          padding: 1.6rem 2.4rem 1.6rem 2.4rem;
        }
        .sidebar-item {
          background-color: var(--color-black);
          width: 100%;
          display: flex;
          justify-content: space-between;
          transition: all 0.2s linear;
          cursor: pointer;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .sidebar {
    .sidebar-container {
      .header {
        .logo {
          width: 200px;
          height: 38px;
        }
      }
      .sidebar-list {
        .bottom {
          position: static;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar {
    .sidebar-container {
      .header {
        .logo {
          width: 200px;
          height: 38px;
        }
      }
      .sidebar-list {
      }
    }
  }
}

a.active > li.sidebar-item {
  // border: 1px solid var(--color-gray);
  background-color: var(--gray-4) !important;
  color: var(--color-white);
}
a.active > li.sidebar-subitem {
  // border: 1px solid var(--color-gray);
  background-color: var(--gray-4) !important;
  color: var(--color-white);
}
