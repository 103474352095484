.emergency-container {
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
  flex: 70%;
  height: min-content;

  // &:hover {
  //   background: var(--color-gray-300);
  //   transition: all 0.2s linear;
  // }

  .emergency-info {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 0.5fr 0.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 12px;
    grid-template-areas:
      "primary-name primary-name  primary-mobile primary-mobile  primary-relation primary-relation"
      "primary-email primary-email  primary-email3 primary-email3  primary-email3 primary-email3"
      "sec-name sec-name  sec-mobile sec-mobile sec-relation sec-relation ";
  }
  span {
    margin-left: auto;
  }

  .primary-name {
    grid-area: primary-name;
  }
  .primary-email {
    grid-area: primary-email;
  }
  .primary-mobile {
    grid-area: primary-mobile;
    .hidden-title {
      color: var(--color-gray-100);

      margin-bottom: 5px;
    }
    .hidden-value {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      border-bottom: 1px solid var(--color-gray-100);
      transition: all 0.2s linear;
    }
    .input-value-gray {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid var(--color-gray-100);
    }
    .input-value-persianblue {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid var(--color-gray-100);
    }
  }
  .primary-relation {
    grid-area: primary-relation;
  }
  .sec-name {
    grid-area: sec-name;
  }

  .sec-email {
    grid-area: sec-email;
  }
  .sec-mobile {
    grid-area: sec-mobile;
  }
  .sec-relation {
    grid-area: sec-relation;
  }
}
