.addMemberProcess {
  margin: 0 -37px;
  display: flex;
  height: 100vh;
  position: relative;

  .isloading {
    min-height: calc(100vh - 200px);
    display: flex !important ;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    background-color: #1f2937;
  }
  .your-membership {
    margin: 100px 0 0 20px;
    width: 375px;
    animation-duration: 300ms;
    transition: all;
    -moz-transition: all;
    -ms-transition: all;
    -o-transition: all;
    -webkit-transition: all;
    animation-name: moveIn;
    animation-fill-mode: forwards;
    position: relative;
  }
  .your-membership-mobile {
    width: 100%;
    height: 50px;
    position: sticky;
    cursor: default !important;
    bottom: 0;
    border-radius: 2px;
    background-color: var(--color-gray-300);
    border-top: 2px solid var(--color-persianblue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .container {
      position: relative;
      width: 100%;
      height: 100%;
      .collapse-btn {
        position: absolute;
        cursor: pointer;
        top: 0;
        height: 50px;
        width: 100%;
      }
    }
    .title {
      cursor: pointer;
      padding: 2px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
      color: var(--color-persianblue);
    }
  }
  .expanded {
    height: 95%;
  }
  .full-width {
    width: 100% !important;
  }

  .add-member-container {
    width: 68%;
    .hidden {
      display: none;
    }

    .close-icon {
      position: absolute;
      right: 20px;
    }
    header {
      margin-bottom: 50.5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > img:first-child {
        height: 35.34px;
        width: auto;
      }
    }
    .NavbarContainer {
      margin-bottom: 31px;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      color: var(--color-gray-100);
      .navbarItem {
        text-transform: uppercase;
        padding: 14px 24px;
        border: 1px solid transparent;
        border-bottom: 1px solid #797f87;
        span {
          padding-right: 10px;
          font-size: 32px;
          line-height: 28.8px;
          min-width: 20px;
          display: inline-block;
          border-right: 1px solid var(--color-gray-100);
        }
        p {
          vertical-align: super;
          padding-left: 10px;
          display: inline-block;
        }
      }
      .navbarItem.active {
        background-color: #2a3441;
        color: white;
        border-bottom: 4px solid var(--color-persianblue);

        > span {
          color: var(--color-persianblue);
          border-right: 1px solid var(--color-persianblue);
        }
      }
    }
    .step-detail-container {
      position: relative;
      overflow-x: clip;
      width: 100%;
      max-width: 1199px;
      height: calc(100vh - 200px);

      > div {
        min-width: 100%;
        position: absolute;
      }

      .moveOut {
        animation-duration: 300ms;
        transition: all;
        -moz-transition: all;
        -ms-transition: all;
        -o-transition: all;
        -webkit-transition: all;
        animation-name: moveOut;
        animation-fill-mode: forwards;
      }

      .moveIn {
        animation-duration: 300ms;
        transition: all;
        -moz-transition: all;
        -ms-transition: all;
        -o-transition: all;
        -webkit-transition: all;
        animation-name: moveIn;
        animation-fill-mode: forwards;
      }

      .moveInRight {
        animation-duration: 300ms;
        transition: all;
        -moz-transition: all;
        -ms-transition: all;
        -o-transition: all;
        -webkit-transition: all;
        animation-name: moveInRight;
        animation-fill-mode: forwards;
      }

      .rightFullOut {
        animation-duration: 300ms;
        transition: all;
        -moz-transition: all;
        -ms-transition: all;
        -o-transition: all;
        -webkit-transition: all;
        animation-name: rightFullOut;
        animation-fill-mode: forwards;
      }

      .outHidden {
        animation-delay: 400ms;
        transition: all;
        animation-duration: 300ms;
        -moz-transition: all;
        -ms-transition: all;
        -o-transition: all;
        -webkit-transition: all;
        animation-name: hide;
        animation-fill-mode: forwards;
      }
    }
  }
}
.block {
  display: block !important;
}
.overFlowClip {
  max-height: 830px;
  overflow-y: hidden;
}
@keyframes hide {
  from {
    display: block;
  }
  to {
    display: none;
    /* background-color: yellow; */
  }
}
@keyframes rightFullOut {
  from {
    left: 0%;
  }
  to {
    display: none;
    left: 100%;

    /* background-color: yellow; */
  }
}
@keyframes moveInRight {
  from {
    right: 100%;
  }
  to {
    /* background-color: yellow; */
    right: 0%;
  }
}
@keyframes moveIn {
  from {
    left: 100%;
  }
  to {
    /* background-color: yellow; */
    left: 0%;
  }
}
@keyframes moveOut {
  from {
    right: 0;
  }
  to {
    /* background-color: yellow; */
    display: none;
    right: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .addMemberProcess {
    margin: 0 0px;
    .add-member-container .NavbarContainer .navbarItem {
      padding: 8px;
      > span {
        font-size: 20px;
      }
      > p {
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .addMemberProcess {
    .full-width {
      padding-bottom: 50px !important;
    }
    margin: 0 0px;
    .add-member-container .NavbarContainer .navbarItem {
      padding: 8px;
      > span {
        font-size: 16px;
      }
      > p {
        font-size: 10px;
        vertical-align: baseline;
      }
    }
  }
}
